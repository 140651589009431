import axios from 'axios'
const api = "delivery/tablero"

const tablero = {
    getData: (params) => axios.get(`${api}/index`, { params }),
    buscarPedido: (id) => axios.get(`${api}/pedido/${id}`),
    porCedis: (id) => axios.get(`${api}/por-cedis/${id}`),
    updateFranja: (data) => axios.post(`${api}/update-franja`, data),
    buscarDeliveries: (params) => axios.get(`${api}/buscar-delivery`, { params }),
    buscarLeecheros: (params) => axios.get(`${api}/buscar-leecheros`, { params }),
    showPedido: (id) => axios.get(`${api}/show-pedido/${id}`),
}
export default tablero
